* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  margin: 0;
  padding: 0;
}
